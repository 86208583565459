import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Button from '../../shared/Button/Button'
import StickyLayoutNavLink from '../../shared/StickyLayout/StickyLayoutNavLink'

const FaqsIndexNav = (props) => {
  const { currentNav, currentSearch, handleClearSearch, handleClick, navItems } = props

  return (
    <>
      {currentSearch ? (
        <Button
          element='button'
          color='primary'
          className='w-full'
          onClick={handleClearSearch}
        >
          Clear Search
        </Button>
      ) : (
        <ul>
          {navItems.map((item, itemIndex) => {
            return (
              <li
                key={itemIndex}
              >
                <StickyLayoutNavLink
                  href={`#${item.name}`}
                  isCurrent={item.name === currentNav}
                  onClick={(event) => handleClick(event, item.name)}
                >
                  {item.label}
                </StickyLayoutNavLink>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}

FaqsIndexNav.propTypes = {
  currentNav: PropTypes.string.isRequired,
  currentSearch: PropTypes.string.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  navItems: PropTypes.array.isRequired
}

export default FaqsIndexNav
