import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Container from '../Container/Container'

const StickyLayout = (props) => {
  const { breakpoint, children, nav } = props
  const leftSpacingClassName = classNames('xxl:pl-80', {
    'md:pl-64': breakpoint === 'md',
    'lg:pl-64': breakpoint === 'lg'
  })

  return (
    <div className='relative'>
      <Container
        className={classNames('hidden', {
          'md:block': breakpoint === 'md',
          'lg:block': breakpoint === 'lg'
        })}
      >
        <div className='w-56 absolute top-0 h-full'>
          <div className='sticky top-0 pt-4 z-20'>
            <nav className='shadow-lg bg-white'>
              {nav}
            </nav>
          </div>
        </div>
      </Container>
      {children({ leftSpacingClassName })}
    </div>

  )
}

StickyLayout.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  nav: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element
  ]).isRequired
}

export default StickyLayout
