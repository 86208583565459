import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Content Components
import Faqs from '../../elements/Faqs/Faqs'

// Generic
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Button from '../../shared/Button/Button'

const FaqsIndexSearchResults = (props) => {
  const { handleClearSearch, leftSpacingClassName, noResultsBody, searchResults } = props

  return (
    <div
      className={classNames('space-y-16 pt-4', leftSpacingClassName)}
      id='search'
    >
      <h2 className='h3 mb-4'>Search results</h2>
      {searchResults.length ? (
        <Faqs
          isOpen
          items={searchResults.map(faq => {
            return {
              header: (
                <HtmlContent
                  html={faq.faqs_group_header.html}
                  element='span'
                />
              ),
              body: (
                <HtmlContent
                  html={faq.faqs_group_body.html}
                  className='c-prose'
                />
              )
            }
          })}
        />
      ) : (
        <HtmlContent
          html={noResultsBody.html}
          className='c-prose'
        />
      )}
      <Button
        element='button'
        color='primary'
        className='w-full md:hidden'
        onClick={handleClearSearch}
      >
        Clear Search
      </Button>
    </div>
  )
}

FaqsIndexSearchResults.propTypes = {
  handleClearSearch: PropTypes.func.isRequired,
  leftSpacingClassName: PropTypes.string.isRequired,
  noResultsBody: PropTypes.object.isRequired,
  searchResults: PropTypes.array.isRequired
}

export default FaqsIndexSearchResults
