import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import Button from '../../shared/Button/Button'

const FaqsIndexMobileNav = (props) => {
  const { currentNav, currentSearch, handleClick, navItems } = props

  return (
    <Container
      className={classNames('md:hidden mb-10 overflow-hidden', {
        hidden: currentSearch
      })}
    >
      <ul className='flex justify-center'>
        {navItems.map((item, itemIndex) => {
          return (
            <li
              key={itemIndex}
            >
              <Button
                element='button'
                color={item.name === currentNav ? 'secondary' : 'primary'}
                className={classNames('px-4 text-base', {
                  'pointer-events-none focus:text-black focus:border-black': item.name === currentNav,
                  'border-primary': item.name !== currentNav
                })}
                onClick={(event) => handleClick(event, item.name)}
                icon={item.name !== currentNav}
              >
                {item.label}
              </Button>
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

FaqsIndexMobileNav.propTypes = {
  currentNav: PropTypes.string.isRequired,
  currentSearch: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  navItems: PropTypes.array.isRequired
}

export default FaqsIndexMobileNav
