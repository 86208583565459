import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Content Components
import Faqs from '../../elements/Faqs/Faqs'

// Generic
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

const FaqsIndexList = (props) => {
  const { currentNav, data, leftSpacingClassName, navItems } = props

  return navItems.map((navItem, navItemIndex) => {
    return (
      <div
        key={navItemIndex}
        className={classNames('space-y-16 pt-4', leftSpacingClassName, {
          'sr-only': currentNav !== navItem.name
        })}
        id={navItem.name}
      >
        {data[navItem.name].map((item) => {
          return (
            <div
              key={item.id}
              className='border-b border-gray-lighter'
            >
              <h2 className='h3 mb-4'>{item.primary.faqs_group_title.text}</h2>
              <Faqs
                canAnimate
                items={item.items.map(faq => {
                  return {
                    header: faq.faqs_group_header.text,
                    body: (
                      <HtmlContent
                        html={faq.faqs_group_body.html}
                        className='c-prose'
                      />
                    )
                  }
                })}
              />
            </div>
          )
        })}
      </div>
    )
  })
}

FaqsIndexList.propTypes = {
  currentNav: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  leftSpacingClassName: PropTypes.string.isRequired,
  navItems: PropTypes.array.isRequired,
  canAnimate: PropTypes.bool
}

export default FaqsIndexList
