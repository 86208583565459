import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const StickyLayoutNavLink = (props) => {
  const { children, href, isCurrent, onClick } = props

  return (
    <a
      href={href}
      className={classNames('block px-6 py-4 leading-none border-r-2', {
        'border-primary': isCurrent,
        'border-white hover:border-primary hover:border-opacity-25': !isCurrent
      })}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

StickyLayoutNavLink.propTypes = {
  href: PropTypes.string,
  isCurrent: PropTypes.bool,
  onClick: PropTypes.func
}

export default StickyLayoutNavLink
