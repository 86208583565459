import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Layout
import Seo from '../../components/layout/Seo/Seo'

// Views
import FaqsIndex from '../../components/views/FaqsIndex/FaqsIndex'

const FaqsPage = (props) => {
  return (
    <>
      <Seo
        title={props.data.faqs.data.title.text}
        customTitle={props.data.faqs.data.meta_title}
        description={props.data.faqs.data.meta_description.text}
        image={props.data.faqs.data.social_image.url}
        slug={props.location.pathname}
      />
      <FaqsIndex data={props.data.faqs.data} />
    </>
  )
}

FaqsPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default FaqsPage

export const PageQuery = graphql`
  query FaqsPageQuery {
    faqs: prismicFaqsPage {
      data {
        title {
          text
        }
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
        hero_title {
          text
        }
        no_results_body {
          html
        }
        group_title_students {
          text
        }
        group_title_businesses {
          text
        }
        students {
          ... on PrismicFaqsPageStudentsFaqsGroup {
            id
            primary {
              faqs_group_title {
                text
              }
            }
            items {
              faqs_group_header {
                text
              }
              faqs_group_body {
                html
                text
              }
            }
          }
        }
        businesses {
          ... on PrismicFaqsPageBusinessesFaqsGroup {
            id
            primary {
              faqs_group_title {
                text
              }
            }
            items {
              faqs_group_header {
                text
              }
              faqs_group_body {
                html
                text
              }
            }
          }
        }
        body {
          ... on PrismicFaqsPageBodyPreFooter {
            id
            slice_type
            primary {
              pre_footer_title {
                text
                raw
              }
              background_color
            }
            items {
              pre_footer_cta_style
              pre_footer_cta_label {
                text
              }
              pre_footer_cta_link {
                ...Link
              }
            }
          }
        }
      }
    }
  }
`
