import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import jump from 'jump.js'

// Content Components
import PageElements from '../../elements/PageElements'
import HeroMinimal from '../../elements/HeroMinimal/HeroMinimal'

// Generic
import Container from '../../shared/Container/Container'
import StickyLayout from '../../shared/StickyLayout/StickyLayout'
import SearchForm from '../../shared/SearchForm/SearchForm'

// Local
import FaqsIndexMobileNav from './FaqsIndexMobileNav'
import FaqsIndexNav from './FaqsIndexNav'
import FaqsIndexSearchResults from './FaqsIndexSearchResults'
import FaqsIndexList from './FaqsIndexList'

const FaqsIndex = (props) => {
  const { data } = props

  /**
   * Default FAQ pages
   * @type {Array}
   */
  const navItems = [{
    label: data.group_title_students.text,
    name: 'students'
  }, {
    label: data.group_title_businesses.text,
    name: 'businesses'
  }]

  const inputElement = useRef(null)
  const resultsElement = useRef(null)

  const [currentNav, setCurrentNav] = useState(navItems[0].name)
  const [currentSearch, setCurrentSearch] = useState('')

  /**
   * Toggle between sections (students/businesses), and scrolls back to the top
   * of the main results section.
   * @param  {Object} event
   * @param  {String} name
   * @return {Void}
   */
  const handleClick = (event, name) => {
    event.preventDefault()

    setCurrentNav(name)

    if (resultsElement.current) {
      resultsElement.current.scrollIntoView()
    }
  }

  /**
   * Update the search value state
   * @param  {Object} event
   * @return {Void}
   */
  const handleSearchInputChange = (event) => {
    setCurrentSearch(event.target.value.toLowerCase())
  }

  /**
   * Resets the search
   * @param  {Object} event
   * @return {Void}
   */
  const handleClearSearch = (event) => {
    event.preventDefault()
    setCurrentSearch('')
    inputElement.current.value = ''
  }

  /**
   * Scroll down to the results section when the form is submitted
   * @param  {Object} event
   * @return {Void}
   */
  const handleSubmit = (event) => {
    event.preventDefault()

    jump('#results')
  }

  /**
   * Add a highlight around text which matches the search term
   * @param  {String} text
   * @param  {String} highlight
   * @return {String}
   */
  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    const res = parts.map((part) => {
      if (part.toLowerCase() === highlight.toLowerCase()) {
        // Use regular old HTML here instead of JSX, we'll convert to JSX later
        return `<span class="bg-secondary-light">${part}</span>`
      }
      // If it doesn't match, just return that part of the string as normal
      return part
    }).join('')

    return res
  }

  /**
   * Find search results from all groups and return an array or results
   * @return {Array}
   */
  const findMatchingItems = () => {
    // Return early if there isn't a search query
    if (!currentSearch) {
      return []
    }
    // Get items for the array, then flatten
    const itemsToSearch = navItems.map((navItem) => {
      return data[navItem.name]
    }).reduce((acc, curr) => {
      return acc.concat(...curr)
    }, [])

    const results = []
    // Loop through each item group...
    itemsToSearch.forEach((item) => {
      // ...and through each subItem...
      item.items.forEach((subItem) => {
        // Check if the header or body contain the search query
        if (subItem.faqs_group_header.text.toLowerCase().includes(currentSearch) || subItem.faqs_group_body.text.toLowerCase().includes(currentSearch)) {
          // If they do, return the highlighted values
          results.push({
            faqs_group_header: {
              // Add to the `html` key here (instead of `text` where it
              // originally came from) as we might get HTML if the search
              // matches something in the header
              html: getHighlightedText(subItem.faqs_group_header.text, currentSearch)
            },
            faqs_group_body: {
              html: getHighlightedText(subItem.faqs_group_body.html, currentSearch)
            }
          })
        }
      })
    })

    return results
  }

  const searchResults = findMatchingItems()

  return (
    <>
      <HeroMinimal>
        <h1 className='mb-10'>{data.hero_title.text}</h1>
        <SearchForm
          handleSubmit={handleSubmit}
          handleSearchInputChange={handleSearchInputChange}
          inputElement={inputElement}
        />
      </HeroMinimal>
      <div
        className='py-10 md:py-20 -mt-24 bg-white relative'
        id='results'
        ref={resultsElement}
      >
        <FaqsIndexMobileNav
          currentNav={currentNav}
          currentSearch={currentSearch}
          handleClick={handleClick}
          navItems={navItems}
        />
        <StickyLayout
          breakpoint='md'
          nav={
            <FaqsIndexNav
              currentNav={currentNav}
              currentSearch={currentSearch}
              handleClearSearch={handleClearSearch}
              handleClick={handleClick}
              navItems={navItems}
            />
          }
        >
          {({ leftSpacingClassName }) => {
            return (
              <Container>
                {currentSearch ? (
                  <FaqsIndexSearchResults
                    handleClearSearch={handleClearSearch}
                    leftSpacingClassName={leftSpacingClassName}
                    noResultsBody={data.no_results_body}
                    searchResults={searchResults}
                  />
                ) : (
                  <FaqsIndexList
                    currentNav={currentNav}
                    data={data}
                    leftSpacingClassName={leftSpacingClassName}
                    navItems={navItems}
                  />
                )}
              </Container>
            )
          }}
        </StickyLayout>
      </div>
      <PageElements slices={data.body} />
    </>
  )
}

FaqsIndex.propTypes = {
  data: PropTypes.object.isRequired
}

export default FaqsIndex
